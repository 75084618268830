<template>
  <div>
    <div :class="'mobileNavBar ' + deviceType">
      <div class="appBar-header">
        <div @click="routeBack('/bugun',false)" class="header-route-back">
          <span v-if="deviceType === 'ios'">
            <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
          </span>
          <span v-else>
            <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
          </span>
        </div>
        <div class="header-title-container">
          <p class="header-title">
            Arama sonuçları
          </p>
        </div>
        <div @click="openSearchModal()">
          <img src="https://gcdn.bionluk.com/site/icon/search_icon_haluk.png" alt="filter-icon" style="width: 18px">
        </div>
      </div>
    </div>
    <div class="user-search-container">
      <div class="user-search-info">
        <p class="user-search-info-header"><span class="user-search-header-info-bold">"{{ term }}"</span> için arama sonuçları</p>
        <p class="user-search-info-description">
          <span @click="$router.push('/usersearch?term='+ term);">Freelancerlarda ara '{{ term }}'</span>
        </p>
      </div>
      <div class="user-search-results-section">
        <loader v-if="!pageLoaded"></loader>
        <div class="user-search-results-list-section" v-else>
          <div class="search-gig-container-main">
            <div class="search-gig-container-inner">
              <single-gig :impression-u-u-i-d="impressionUUID" :item="gig" v-for="(gig, gin) in gigs" :key="gin" class="gig-list-gig" style="margin-bottom: 25px;"></single-gig>
            </div>
            <div v-if="!gigs.length && pageLoaded" style="text-align: center">
              <div class="notodoitems" style="display: flex;justify-content: center;flex-direction: column;align-items: center;background-color: #FFF;word-break: break-word">
                <h4>Sonuç bulunamadı.</h4>
                <div class="hiddendiv"></div>
                <span style="line-height: 60px;width: 100px; height: 100px; padding-top: 20px;">
                  <img style="width: 75px; height: 75px;" src="https://gcdn.bionluk.com/site/general/no-foun-gig.png">
                </span>
                <p class="h1_2" style=" margin: 0 auto; font-size:14px; font-weight:400!important;color: #8b95a1!important;">
                  Aradığın hizmeti bulmak için, hızlıca bir istek oluşturabilir ve freelancer topluluğumuzdan teklifler alabilirsin.
                </p>
                <div class="hiddendiv"></div>

                <router-link to="/panel/istekler/">
                  <button class="super-button">İstek Oluştur</button>
                </router-link>

                <div class="hiddendiv10"></div>
              </div>
            </div>
            <div v-if="seemorediv && !seemoreLoading" @click="getDeeds(offset)" style="cursor: pointer; padding-bottom:10px;font-size: 16px; font-weight: 500;text-align: center; color: #2d3640;">
              Daha fazla göster +
            </div>
            <div class="hiddendiv"></div>
            <loader v-if="seemoreLoading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 import singleGig from "@/pages/components/singleGig"
 export default {
    components: { singleGig },
    name: "src-pages-body-search-v1-mobile",
    data() {
      return {
        impressionUUID: null,
        offset: 0,
        limit: 16,
        seemorediv: null,
        pageLoaded: false,
        seemoreLoading: false,
        totalCount: null,
        gigs: [],
        categorySelect: [],
        selectedCategorySlug: null,
        slug: null,
        term: '',
        orderBy: 1,
        duration: null
      }
    },
    methods: {
      openSearchModal() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.SEARCH, info: { duration: this.duration, searchTerm: this.term }});
      },
      prepareHtmlTitleAndDescription(term) {
        if (this.selectedCategorySlug) {
          this.$store.state.indexHTMLTitle = `${term} ${this.selectedCategorySlug} içinde İş İlanları | Bionluk`;
          this.$store.state.indexHTMLMetaDescription = `${term} ${this.selectedCategorySlug} içinde İş İlanları | Bionluk`;
        } else {
          this.$store.state.indexHTMLTitle = `İş İlanları | Bionluk içinde ${term}`;
          this.$store.state.indexHTMLMetaDescription = `İş İlanları | Bionluk içinde ${term}`;
        }
      },
      async prepareCategorySelect(category) {
        let categorySelect = [];
        if (category) {
          if (!category.parent_id) {
            const categories = await this.$store.state.categoriesPromise
            const foundChildCategories = categories.filter(({ parent_id }) => parent_id === category.id)
            categorySelect.push({
              name: category.name,
              selected: true,
              slug: category.slug
            });

            foundChildCategories.forEach(childCategory => {
              categorySelect.push({
                name: childCategory.name,
                selected: false,
                slug: childCategory.slug
              });
            })
          } else {
            const categories = await this.$store.state.categoriesPromise
            const foundChildCategories = categories.filter(({ parent_id }) => parent_id === category.parent_id)
            let foundParentCategory = await this.findCategoryByID(category.parent_id)

            categorySelect.push({
              name: foundParentCategory.name,
              selected: false,
              slug: foundParentCategory.slug
            });

            foundChildCategories.forEach(childCategory => {
              categorySelect.push({
                name: childCategory.name,
                selected: childCategory.id === category.id,
                slug: childCategory.slug
              });
            })
          }
        } else {
          const categories = await this.$store.state.categoriesPromise
          const allChildCategories = categories.filter(category => !!category.parent_id)

          allChildCategories.forEach(childCategory => {
            categorySelect.push({
              name: childCategory.name,
              selected: false,
              slug: childCategory.slug
            });
          });
        }


        this.categorySelect = categorySelect;

      },
      getDeeds(offset) {
        this.offset = offset;
        if (offset === 0) {
          this.gigs = [];
          this.pageLoaded = false;
        } else {
          this.seemoreLoading = true;
        }
        this.searchDeeds2(
          {
            term:this.term,

            limit: this.limit,
            count: true,
            offset: (offset) * this.limit,
            orderBy: Number(this.orderBy),
            scoringProfile:'term_search',
          }
        )
          .then(result => {
            return this.api.general.getGigLikes(result.results.map(res => res.id))
              .then(async ({data}) => {
                let likeResult = data;


                result.results.forEach(gig => {
                  let foundLike = likeResult.data.find(({ id }) => id === gig.id)
                  gig.portfolios = JSON.parse(gig.portfolios)
                  gig.likeStatus = foundLike.likeStatus;
                });

                if (result.count) {
                  this.totalCount = result.count;
                }
                if (result.results.length === 0) {
                  this.totalCount = 0;
                }


                if(result.results.length > 0){

                  this.impressionUUID = this.Helper.generateUUID();
                  let trackGigObj = [];
                  result.results.forEach((gig, gigdex) => {
                    trackGigObj.push({
                      gig_id:gig.id,
                      seller_id:gig.seller_id,
                      order_id: gigdex + 1
                    })
                  });
                  if(trackGigObj.length > 0){
                    let trackingProps = {
                      page:'search',
                      term:this.route.query.term,
                      placement:'default',
                      info: trackGigObj,
                      title: this.impressionUUID
                    };
                    this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
                  }
                }

                this.gigs = [...this.gigs, ...result.results];
                this.offset += this.limit;
                this.seemorediv = this.offset < this.totalCount;

                this.prepareHtmlTitleAndDescription(this.term);

                let trackProps = {}
                if (this.route.query.slug) {
                  let foundCategory = await this.findCategoryBySlug(this.route.query.slug);
                  let foundParentCategory = await this.findCategoryByID(foundCategory.parent_id);
                  if (!foundCategory.parent_id) {
                    trackProps.categoryId = foundCategory.id;
                    trackProps.parentCategoryId = null;
                  } else {
                    trackProps.categoryId = foundCategory.id;
                    trackProps.parentCategoryId = foundParentCategory.id;
                  }
                }


                if (offset === 0) {
                  this.Helper.trackEvents.pageView(this.Helper.searchPageProps(this.totalCount, this.route.query, trackProps));
                }

                this.pageLoaded = true;
                this.seemoreLoading = false;
              })
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },
    watch: {
      'route.query.term': async function () {
        this.slug = (this.route.query.slug && this.route.query.slug !== 'undefined') ? this.route.query.slug : null;
        let term = (this.route.query.term && this.route.query.term !== 'undefined') ? this.route.query.term : null;
        this.term = decodeURIComponent(term);
        this.duration = (this.route.query.duration && this.route.query.duration !== 'undefined') ? this.route.query.duration : null;
        this.orderBy = (this.route.query.orderBy && this.route.query.orderBy !== 'undefined') ? this.route.query.orderBy : 1;
        this.selectedCategorySlug = this.slug;

        let foundCategory = await this.findCategoryBySlug(this.selectedCategorySlug);
        this.prepareCategorySelect(foundCategory);

        this.offset = 0;
        this.impressionUUID = this.Helper.generateUUID();
        this.getDeeds(0, true);
      }
    },
    async created() {
      this.EventBus.$on('search', payload => {
        this.$router.push(`/search?term=${encodeURIComponent(payload.searchTerm.toLocaleLowerCase('tr-TR'))}`);
      });

      this.slug = (this.route.query.slug && this.route.query.slug !== 'undefined' && this.route.query.slug !== "null") ? this.route.query.slug : null;
      let term = (this.route.query.term && this.route.query.term !== 'undefined') ? this.route.query.term : null;
      this.term = decodeURIComponent(term);
      this.duration = (this.route.query.duration && this.route.query.duration !== 'undefined' && this.route.query.duration !== "null") ? this.route.query.duration : null;
      this.orderBy = (this.route.query.orderBy && this.route.query.orderBy !== 'undefined') ? this.route.query.orderBy : 1;
      this.selectedCategorySlug = this.slug;


      let foundCategory = await this.findCategoryBySlug(this.slug);
      this.prepareCategorySelect(foundCategory);
      this.offset = 0;

      this.impressionUUID = this.Helper.generateUUID();
      this.getDeeds(0);
    },
    beforeDestroy() {
      this.EventBus.$off('search');
    }
  }

</script>

<style scoped lang="scss">
  .search-gig-container-main {
    background-color: #f4f5f7;
  }

  .search-gig-container-inner {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  .search-gig-container {
    margin: 10px;
    height: 150px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    display: inline-block;
    width: auto;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    vertical-align: top;
  }

  .super-drop-tek-cizgi {
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 90%;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    font-family: 'sofia-pro';
    background-color: #fefdfd;
    color: #2d3640;
    border: none;
    position: relative;
    width: 260px;
    border-bottom: solid 1px #bfc8d2;
    font-size: 14px;
    padding: 4px 5px 6px;
    padding-left: 10px;
  }

  .category {
    margin-top: 10px;
  }

  .duration {
    margin-top: 10px;
  }

  .super-drop-tek-cizgi:hover {

    border: none;
    border-bottom: solid 1px #2d3640;
    color: #2d3640;
    cursor: pointer;
  }

  .user-search-container {
    padding: 56px 10px 50px;
    background-color: #f4f5f7;
  }

  .user-search-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .user-search-info-header {
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      color: #4b4f52;

      padding-top: 50px;
      .user-search-header-info-bold {
        font-weight: 500;
      }
    }
    .user-search-info-description {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #2d3640;
      text-decoration: underline;
      padding: 23px 0 40px;
      span {
        cursor: pointer;
      }
    }
  }

  .user-search-results-section {
    display: flex;
    justify-content: center;
    .user-search-results-list-section {
      margin-top: 5px;
    }

    .user-search-results-list {
      padding: 0;
      display: flex;
      flex: 1 1 0;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
</style>
